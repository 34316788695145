// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-macos-jsx": () => import("./../../../src/pages/macos.jsx" /* webpackChunkName: "component---src-pages-macos-jsx" */),
  "component---src-pages-repos-jsx": () => import("./../../../src/pages/repos.jsx" /* webpackChunkName: "component---src-pages-repos-jsx" */),
  "component---src-pages-toto-jsx": () => import("./../../../src/pages/toto.jsx" /* webpackChunkName: "component---src-pages-toto-jsx" */),
  "component---src-pages-uses-jsx": () => import("./../../../src/pages/uses.jsx" /* webpackChunkName: "component---src-pages-uses-jsx" */),
  "component---src-pages-vscode-jsx": () => import("./../../../src/pages/vscode.jsx" /* webpackChunkName: "component---src-pages-vscode-jsx" */)
}

